<script>
export default {}
</script>

<template>
  <v-banner two-line color="blue lighten-5" transition="hide-on-leave">
    <v-avatar slot="icon" color="primary" size="40">
      <v-icon color="white"> mdi-thumb-up </v-icon>
    </v-avatar>
    <div class="blue--text font-weight-bold mb-2">Application successfully submitted!</div>
    <div class="blue--text font-weight-medium mb-2">You can still refine it.</div>
    <template v-slot:actions="{ dismiss }" align="center"> <v-spacer /><v-btn medium class="primary" @click="dismiss">OK</v-btn><v-spacer /></template>
  </v-banner>
</template>
